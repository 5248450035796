const aliases = {
    home: {
        page: true,
        name: "app.root.h1.home"
    },
    deposit: {
        page: false,
        name: "cashbox",
        private: true
    },
    withdrawal: {
        page: false,
        name: "cashbox",
        content: { "tab1": 2, "tab2": 1 }
    },
    login: {
        page: false,
        name: "login"
    },
    registration: {
        page: false,
        name: "registration"
    },
    fastRegistration: {
        page: true,
        name: "app.root.l1.paynplay"
    },
    promo: {
        page: true,
        name: "app.root.l1.promo.list"
    },
    lamborghini: {
        page: true,
        name: "app.root.l1.lamborghini"
    },
    realGame: {
        page: true,
        name: "app.root.game.real"
    },
    santa_slope: {
        page: true,
        name: "app.root.game.real",
        params: { name: 'santas-slope' }
    },
    tournaments: {
        page: true,
        name: "app.root.l1.tournament.list"
    },
    profile: {
        page: true,
        name: "app.root.l1.account.info"
    },
    successBonusActivation: {
        page: true,
        name: "app.root.l1.account.bonuses"
    },
    bonuses: {
        page: true,
        name: "app.root.l1.account.bonuses"
    },
    paynplay: {
        page: true,
        name: "app.root.l1.paynplay"
    },
    collection: {
        page: true,
        name: "app.root.l1.gh.casino.collection",
        params: { name: 'top' }
    },
    promoTennis: {
        page: true,
        name: "app.root.l1.sportpage.prelive",
        params: {
            champids: "3158"
        }
    },
    tennisTournament: {
        page: true, private: true,
        name: "app.root.l1.sportpage.prelive",
        params: { champids: 3013 }
    },
    live: {
        page: true,
        name: "app.root.l1.gh.live"
    },
    category: {
        page: true,
        name: "app.root.l1.gh.casino.collection",
        params: { name: 'top' }
    },
    games: {
        page: true,
        name: "app.root.l1.gh.casino.gamehall"
    },
    gameHall: {
        page: true,
        name: "app.root.l1.gh.casino.gamehall"
    },
    sportEvent: {
        page: true,
        name: "app.root.l1.sportpage.prelive"
    },
    sport: {
        page: true,
        name: "app.root.l1.sportpage.prelive"
    },
    missedData: {
        page: true,
        name: "app.root.missedData"
    },
    bonus_crab: {
        page: true,
        name: "app.root.claw_games.gamepage",
        params: {
            name: "cash-crab"
        }
    },
    cash_crab: {
        page: true,
        name: "app.root.claw_games.gamepage",
        params: {
            name: "cash_crab"
        }
    },
    claw: {
        page: true,
        name: "app.root.claw_games.gamepage"
    },
    terms: {
        page: true,
        name: "app.root.l1.info.terms"
    },
    evolution: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "evolution"
        }
    },
    pragmatic: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "pragmatic"
        }
    },
    playngo: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "playngo"
        }
    },
    halloween: {
        page: true,
        name: "app.root.l1.tournament.page",
        params: {
            name: "halloween"
        }
    },
    //-world cup
    worldCupSport: {
        page: true,
        name: "app.root.l1.sportpage.prelive"
    },
    worldCupPragmatic: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "pragmatic"
        }
    },
    worldCupPlayngo: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "playngo"
        }
    },
    worldCupElagames: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "ela"
        }
    },
    worldCupEvolution: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "evolution"
        }
    },
    worldCupBetNow: {
        page: true,
        name: "app.root.l1.sportpage.prelive",
        params: {
            champids: "3146"
        }
    },
    worldcupTournament: {
        page: true,
        name: "app.root.l1.tournament.world-cup"
    },
    promotions: {
        page: true,
        name: "app.root.l1.promo.list"
    },
    relax: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "relax"
        }
    },
    skywind: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "skywind"
        }
    },
    netent: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "netent"
        }
    },
    pushgaming: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "pushgaming"
        }
    },
    playtech: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "playtech"
        }
    },
    quickspin: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "quickspin"
        }
    },
    redtiger: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "redtiger"
        }
    },
    christmasTournament: {
        page: true,
        name: "app.root.l1.tournament.page",
        params: {
            name: "party-raffle"
        }
    },
    threeoaks: {
        page: true,
        name: "app.root.layer1.layer2.layer3.catalog.provider.hall",
        params: {
            name: "threeoaks"
        }
    },
    ela: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "ela"
        }
    },
    spinomenal: {
        page: true,
        name: "app.root.l1.gh.casino.provider",
        params: {
            name: "spinomenal"
        }
    },
    summer: {
        page: true,
        name: "app.root.seasons"
    },
    summerGame: {
        page: true,
        name: "app.root.summer-game"
    },
    soccer: {
        page: true,
        name: "app.root.l1.sportpage.prelive",
        params: {
            sportids: "66"
        }
    },
    tennis: {
        page: true,
        name: "app.root.l1.sportpage.prelive",
        params: {
            sportids: "68"
        }
    },
    bet: {
        page: true,
        name: "app.root.l1.sportpage.live"
    },
    euroFootball: {
        page: true,
        name: "app.root.l1.sportpage.prelive",
        params: {
            champids: "3031"
        }
    },
    euro2024: {
        page: true,
        name: "app.root.l1.tournament.euro2024"
    },
};

export { aliases };
